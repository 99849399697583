/** @format */

import { AnimatedCardEntry } from '@/types/shared';
import { Document } from '@contentful/rich-text-types';
import { FunctionComponent as FC, useContext } from 'react';
import { breakpointUpTo } from 'src/theme/mixins';
import { FindColor } from 'src/utils/findThemeColor';
import styled, { ThemeContext } from 'styled-components';
import { Container, ContainerWrapper } from '../ui/Layout';
import { RichText } from '../ui/RichText';
import { Icon } from './Icon';

export const IconsDescriptionSection: FC<Props> = ({ title, icons, background, negativeMargin }) => {
  const theme = useContext(ThemeContext);

  return (
    <CustomContainerWrapper $color={theme!.color.white} $negativeMargin={negativeMargin}>
      <CustomContainer>
        <Wrapper
          $iconsLength={icons.length}
          $background={FindColor(background.replace(' ', '.').toLowerCase(), theme!.color)}
        >
          <CustomStyleRichText $iconsLength={icons.length} $background={background}>
            <RichText text={title} />
          </CustomStyleRichText>
          <IconsWrapper $iconsLength={icons.length} $background={background}>
            {icons &&
              icons.length > 0 &&
              icons.map((card, index) => (
                <Icon iconsLength={icons.length} background={background} key={index} icon={card} />
              ))}
          </IconsWrapper>
        </Wrapper>
      </CustomContainer>
    </CustomContainerWrapper>
  );
};

const CustomContainerWrapper = styled(ContainerWrapper)<{ $negativeMargin: boolean }>`
  z-index: 9;
  ${({ $negativeMargin }) =>
    $negativeMargin &&
    `
    background-color:transparent;
    z-index: 2;
    position: relative;
    margin-bottom: calc(clamp(5.75rem, 5.5833rem + 0.7407vw, 6.25rem) * -1);
    `}
`;

const CustomContainer = styled(Container)`
  ${breakpointUpTo.md`
    width: 100%;
  `}
`;

const IconsWrapper = styled.div<{ $iconsLength: number; $background: string }>`
  --column-width: ${({ $iconsLength, $background }) => {
    if ($iconsLength > 3 && $background.replace(' ', '.').toLowerCase() === 'teal.light') return '17rem';
    else if ($iconsLength > 3) return '18.75rem';
    return 'clamp(18.75rem, 17.5208rem + 5.4630vw, 22.4375rem)';
  }};

  display: flex;
  flex-wrap: wrap;

  justify-content: ${({ $iconsLength, $background }) => {
    if ($iconsLength > 3 && $background.replace(' ', '.').toLowerCase() === 'teal.light') return 'center';
    return 'space-around';
  }};

  gap: ${({ $iconsLength, $background }) => {
    if ($iconsLength > 3 && $background.replace(' ', '.').toLowerCase() === 'teal.light')
      return '2rem clamp(3.75rem, 1.8333rem + 8.5185vw, 9.5rem)';
    if ($iconsLength > 3) return '1.88rem 2.44rem';
    return 'clamp(3.75rem, 3.6467rem + 0.4593vw, 4.06rem)';
  }};

  @media screen and (min-width: 1280px) and (max-width: 1438px) {
    gap: ${({ $iconsLength, $background }) => {
      if ($iconsLength > 3 && $background.replace(' ', '.').toLowerCase() === 'teal.light')
        return '2rem clamp(3.75rem, 1.8333rem + 8.5185vw, 9.5rem);';
      if ($iconsLength > 3) return '1.88rem 2.44rem';
      return 'clamp(0.55rem, -14.6500rem + 19.0000vw, 2.45rem);';
    }};
  }

  .iconWrapper {
    width: var(--column-width);
  }
`;

const CustomStyleRichText = styled(Container)<{ $background: string; $iconsLength: number }>`
  max-width: clamp(20.625rem, 4.6042rem + 71.2037vw, 68.6875rem);
  text-align: center;

  padding-bottom: ${({ $iconsLength, $background }) =>
    $iconsLength > 3
      ? $background.replace(' ', '.').toLowerCase() === 'teal.light'
        ? 'clamp(3.75rem, 3.5400rem + 0.9333vw, 4.38rem)'
        : 'clamp(1.88rem, 1.1333rem + 3.3185vw, 4.12rem)'
      : 'clamp(3.75rem, 3.1633rem + 2.6074vw, 5.51rem)'};

  h2 {
    padding-bottom: 0;
    color: ${({ theme, $background }) =>
      $background.replace(' ', '.').toLowerCase() === 'teal.light' ? theme.color.white : theme.color.teal.dark};
    max-width: 71.875rem;
    margin-inline: auto;
    white-space: pre-line;

    em {
      color: ${({ theme, $background }) =>
        $background.replace(' ', '.').toLowerCase() === 'teal.light' ? theme.color.yellow : theme.color.teal.dark};
    }

    ${breakpointUpTo.sm`
      white-space: normal;
    `}
  }
  h3 {
    padding-bottom: 0;
    color: ${({ theme }) => theme.color.teal.light};
  }
`;

const Wrapper = styled.div<{ $iconsLength: number; $background: string }>`
  padding-top: ${({ $iconsLength, $background }) =>
    $iconsLength > 3
      ? $background.replace(' ', '.').toLowerCase() === 'teal.light'
        ? 'clamp(3.75rem, 3.5000rem + 1.1111vw, 4.5rem)'
        : 'clamp(3.75rem, 2.8333rem + 4.0741vw, 6.5rem)'
      : 'clamp(3.75rem, 2.8533rem + 3.9852vw, 6.44rem)'};
  padding-bottom: ${({ $iconsLength, $background }) =>
    $iconsLength > 3
      ? $background.replace(' ', '.').toLowerCase() === 'teal.light'
        ? 'clamp(3.75rem, 2.4767rem + 5.6593vw, 7.57rem)'
        : 'clamp(3.75rem, 2.8333rem + 4.0741vw, 6.5rem);'
      : 'clamp(3.75rem, 2.7933rem + 4.2519vw, 6.62rem)'};
  padding-inline: ${({ $iconsLength, $background }) =>
    $iconsLength > 3
      ? $background.replace(' ', '.').toLowerCase() === 'teal.light'
        ? 'clamp(3.69rem, 2.5867rem + 4.9037vw, 7rem);'
        : '0'
      : 'clamp(1.88rem, 1.0900rem + 3.5111vw, 4.25rem)'};

  overscroll-behavior-x: none;
  background-color: ${({ $background }) => $background};
  border-radius: clamp(2.3125rem, 1.625rem + 3.0556vw, 4.375rem);
`;

type Props = {
  title: Document;
  icons: AnimatedCardEntry[];
  background: string;
  negativeMargin: boolean;
};
