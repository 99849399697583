/** @format */

import { AnimatedCard } from '@/types/shared';
import { Entry } from 'contentful';
import { FunctionComponent as FC, useMemo } from 'react';
import styled from 'styled-components';
import { RichText } from '../ui/RichText';
import { H3, H4 } from '../ui/Typography';

export const Icon: FC<Props> = ({ icon, iconsLength, background }) => {
  const { svg, title, text } = icon.fields;
  const firstSpaceIndex = useMemo(() => title.indexOf(' '), [title]);
  const firstWord = useMemo(() => title.slice(0, firstSpaceIndex + 1), [firstSpaceIndex, title]);
  const remainingText = useMemo(() => title.slice(firstSpaceIndex + 1), [firstSpaceIndex, title]);

  return (
    <IconWrapper className="iconWrapper">
      <div className="SVGWrapper" dangerouslySetInnerHTML={{ __html: svg }} />
      <TitleWrapper $background={background}>
        {iconsLength < 4 && background.replace(' ', '.').toLowerCase() === 'teal.light' ? (
          <>
            <H4>
              {firstWord}
              <br />
              {remainingText}
            </H4>
          </>
        ) : (
          <H3>{title}</H3>
        )}
      </TitleWrapper>
      {text && (
        <CustomRichTextStyle $background={background}>
          <RichText text={text} />
        </CustomRichTextStyle>
      )}
    </IconWrapper>
  );
};

const IconWrapper = styled.div`
  text-align: center;
  .SVGWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 180px;
    svg {
      flex-shrink: 0;
    }
  }
`;

const TitleWrapper = styled.div<{ $background: string }>`
  max-width: 17rem;
  margin-inline: auto;
  padding-bottom: 1.25rem;

  h3,
  h4,
  h5 {
    color: ${({ $background, theme }) =>
      $background.replace(' ', '.').toLowerCase() === 'teal.light' ? theme.color.yellow : theme.color.teal.light};
  }
`;

const CustomRichTextStyle = styled.div<{ $background: string }>`
  p {
    color: ${({ $background, theme }) =>
      $background.replace(' ', '.').toLowerCase() === 'teal.light' ? theme.color.white : theme.color.teal.dark};
  }
`;

type Props = {
  icon: Entry<AnimatedCard>;
  iconsLength: number;
  background: string;
};
